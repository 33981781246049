import { useMsal } from '@azure/msal-react';
import { AuthHelper } from '../auth/AuthHelper';
import { AuthoritiveService, IBucket, IBucketSource } from '../services/AuthoritiveService';

export interface IResult<T> {
    Data?: T;
    Success: boolean;
    Message?: string;
}

export const useAuthoritive = () => {
    const { instance, inProgress } = useMsal();

    const authoritiveService = new AuthoritiveService(process.env.REACT_APP_BACKEND_URI as string);

    const getBuckets = async (user: string): Promise<IResult<IBucket[]>> => {
        const accessToken = await AuthHelper.getSKaaSAccessToken(instance, inProgress);

        try {
            const value = await authoritiveService.getBucketsAsync(accessToken, {
                user,
                useBrowserCache: false,
            });
            return { Data: value, Success: true };
        } catch (e: any) {
            const errorMessage = `Error getting buckets. Details: ${getErrorDetails(e)}`;
            return { Success: false, Message: errorMessage };
        }
    };

    const getBucketSources = async (user: string): Promise<IResult<IBucketSource[]>> => {
        const accessToken = await AuthHelper.getSKaaSAccessToken(instance, inProgress);

        try {
            const value = await authoritiveService.getBucketSourcesAsync(accessToken, {
                user,
                useBrowserCache: false,
            });
            return { Data: value, Success: true };
        } catch (e: any) {
            const errorMessage = `Error getting buckets. Details: ${getErrorDetails(e)}`;
            return { Success: false, Message: errorMessage };
        }
    };

    return {
        getBuckets,
        getBucketSources,
    };
};

function getErrorDetails(e: any) {
    return e instanceof Error ? e.message : String(e);
}
