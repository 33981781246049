import {
    Button,
    DataGrid,
    DataGridBody,
    DataGridCell,
    DataGridHeader,
    DataGridHeaderCell,
    DataGridProps,
    DataGridRow,
    Spinner,
    TableColumnDefinition,
    TableRowId,
    createTableColumn,
    makeStyles,
    shorthands,
    tokens,
} from '@fluentui/react-components';
import { DismissCircle20Regular, SaveRegular } from '@fluentui/react-icons';
import debug from 'debug';
import * as React from 'react';
import { Constants } from '../../../Constants';
import { useChat } from '../../../libs/hooks';
import { useSites } from '../../../libs/hooks/useSite';
import { ISiteInfo } from '../../../libs/models/SelectedSites';
import CacheUtils from '../../../libs/utils/CacheUtils';
import { useAppDispatch, useAppSelector } from '../../../redux/app/hooks';
import { RootState } from '../../../redux/app/store';
import { editConversationSiteSource } from '../../../redux/features/conversations/conversationsSlice';
import { Breakpoints, customTokens } from '../../../styles';
import { TabView } from './TabView';

const log = debug(Constants.debug.root).extend('sites-tab');

const useClasses = makeStyles({
    outerWrapper: {
        display: 'flex',
        flexDirection: 'column',
        rowGap: '20px',
        ...shorthands.margin('10px 0'),
    },
    header: {
        fontSize: tokens.fontSizeBase300,
        fontWeight: 'bold',
        ...shorthands.margin('5px 0'),
    },
    description: {
        fontSize: '1em',
        ...shorthands.margin('5px 0'),
    },
    sitesWrapper: {
        display: 'flex',
        flexDirection: 'column',
        rowGap: '10px',
        ...shorthands.margin('10px 0'),
    },
    selectedSites: {
        display: 'flex',
        flexWrap: 'wrap',
        rowGap: '10px',
        columnGap: '10px',
    },
    selectedSite: {
        alignItems: 'start',
        columnGap: '10px',
        display: 'flex',
    },
    tableWrapper: {
        display: 'flex',
        flexDirection: 'column',
        rowGap: '10px',
        ...shorthands.margin('10px 0'),
    },
    table: {
        backgroundColor: '#fafafa',
    },
    sitesPillsContainer: {
        marginBottom: '10px',
        display: 'flex',
        alignItems: 'center',
        width: '95%',
        flexWrap: 'wrap',
    },
    sitesPillDesc: {
        marginRight: '10px',
        fontWeight: '600',
    },
    sitesPills: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        flexWrap: 'wrap',
    },
    sitesPill: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        alignItems: 'center',
        ...shorthands.margin('8px', '10px', '5px', '0'),
        opacity: 1,
        ...shorthands.padding('1px', '4px', '1px', 0),
        ...shorthands.borderRadius('20px'),
        width: 'auto',
        color: '#ffffff',
        textAlign: 'center',
    },
    sitesPillValue: {
        ...shorthands.flex(1, 0, 'auto'),
        lineHeight: '18px',
        ...shorthands.padding('0px', '5px', '1px', '10px'),
        fontWeight: 400,
        ...Breakpoints.small({
            fontSize: '12px',
        }),
    },
    sitesPillIcon: {
        height: '20px',
        cursor: 'pointer',

        '&:hover': {
            '> svg': {
                color: '#ffffff',
            },
        },
    },
    moreArchivePills: {
        marginLeft: '5px',
        ...shorthands.padding('4px', '0', '0px', '0'),
        color: 'black',
        cursor: 'pointer',
        fontSize: '30px',

        '&:hover': {
            '> svg': {
                color: customTokens.colorBrandBackground,
            },
        },
    },
});

const columns: Array<TableColumnDefinition<ISiteInfo>> = [
    createTableColumn({
        columnId: 'siteName',
        compare: (a, b) => a.siteName.localeCompare(b.siteName),
        renderHeaderCell: () => {
            return <span style={{ fontWeight: 500 }}>Name</span>;
        },
        renderCell: (item) => {
            return item.siteName;
        },
    }),
    createTableColumn({
        columnId: 'siteUrl',
        compare: (a, b) => a.siteUrl.localeCompare(b.siteUrl),
        renderHeaderCell: () => {
            return <span style={{ fontWeight: 500 }}>Url</span>;
        },
        renderCell: (item) => {
            return item.siteUrl;
        },
    }),
];
const columnSizingOptions = {
    name: {
        minWidth: 80,
        defaultWidth: 180,
    },
    Url: {
        defaultWidth: 180,
        minWidth: 120,
        idealWidth: 180,
    },
};

export const SitesTab: React.FC = () => {
    const classes = useClasses();
    const [indexedSites, setIndexedSites] = React.useState<ISiteInfo[]>([]);
    const [selectedSites, setSelectedSites] = React.useState<ISiteInfo[]>([]);
    const [selectedRows, setSelectedRows] = React.useState(new Set<TableRowId>([]));

    const [loading, setLoading] = React.useState(true);
    const sitesService = useSites();
    const { conversations, selectedId } = useAppSelector((state: RootState) => state.conversations);
    const { activeUserInfo } = useAppSelector((state: RootState) => state.app);
    const dataCacheKey = `SitesTab.GetSites.${activeUserInfo?.id}`;

    const chatState = conversations[selectedId];

    const dispatch = useAppDispatch();
    const chat = useChat();

    React.useEffect(() => {
        //Check if the data is in the cache then use from the cache else fetch the data from the server
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const cachedData: ISiteInfo[] | null = CacheUtils.getStoredDataByKey(dataCacheKey, true);
        if (cachedData !== null && cachedData.length > 0) {
            setIndexedSites(cachedData);
            setLoading(false);
            return;
        }
        sitesService
            .getSites()
            .then((results: ISiteInfo[]) => {
                const items: ISiteInfo[] = [];
                results.forEach((site: ISiteInfo) => {
                    items.push({
                        siteName: site.siteName,
                        siteUrl: site.siteUrl,
                        siteId: site.siteId,
                    });
                });
                setLoading(false);
                CacheUtils.setStoredDataByKey(dataCacheKey, items, CacheUtils.dataCacheExpires24Hours, true);
                setIndexedSites(items);
            })
            .catch((error) => {
                const message = `Error submitting chat input: ${(error as Error).message}`;
                log(message);
            });
    }, []);
    React.useEffect(() => {
        if (!chatState.selectedSites) return;
        const selectedSites = chatState.selectedSites ?? ([] as ISiteInfo[]);
        setSelectedRows(new Set<TableRowId>(selectedSites.map((site) => site.siteId)));
        setSelectedSites(selectedSites);
    }, [chatState.selectedSites]);

    const onSelectionChange: DataGridProps['onSelectionChange'] = (_e, { selectedItems }) => {
        setSelectedRows(selectedItems);
    };

    const updateConversationSiteSource = async (updatedSelectedSites: ISiteInfo[]) => {
        await chat.editChat(
            selectedId,
            chatState.title,
            chatState.memoryBalance,
            chatState.chatScope,
            chatState.isHidden,
            updatedSelectedSites,
        );

        dispatch(
            editConversationSiteSource({
                id: selectedId,
                siteSources: updatedSelectedSites,
            }),
        );
    };

    const onSavePreference = () => {
        const updatedSelectedSites = indexedSites.filter((site) => selectedRows.has(site.siteId));

        setSelectedSites(updatedSelectedSites);
        updateConversationSiteSource(updatedSelectedSites).catch((error) => {
            log('An error occured whilst editing chat state', error);
        });
    };
    const onRemoveSelection = (selectedSiteId: string) => {
        const updatedSelectedSites: ISiteInfo[] = selectedSites.filter((site) => site.siteId !== selectedSiteId);
        setSelectedSites(updatedSelectedSites);
        updateConversationSiteSource(updatedSelectedSites).catch((error) => {
            log('An error occured whilst editing chat state', error);
        });
    };

    return (
        <TabView
            title="Select Sources"
            learnMoreDescription="document embeddings"
            learnMoreLink="https://aka.ms/sk-docs-vectordb"
        >
         
                <div className={classes.outerWrapper}>
                    {selectedSites.length > 0 && (
                        <div className={classes.sitesWrapper}>
                            <div
                                className={classes.header}
                            >{`Selected source${selectedSites.length > 1 ? 's' : ''}:`}</div>
                            <div className={classes.sitesPillsContainer}>
                                <div className={classes.sitesPills}>
                                    {selectedSites.map((site) => (
                                        <div
                                            key={site.siteId}
                                            className={classes.sitesPill}
                                            style={{ backgroundColor: tokens.colorBrandBackground }}
                                        >
                                            <div className={classes.sitesPillValue}>{site.siteName}</div>
                                            <div
                                                className={classes.sitesPillIcon}
                                                onClick={() => {
                                                    onRemoveSelection(site.siteId);
                                                }}
                                            >
                                                <DismissCircle20Regular color={'#ffffff'} />
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    )}

                    {loading ? (
                        <Spinner label="Fetching indexed sources..." labelPosition="below" />
                    ) : (
                        <>
                            <div className={classes.tableWrapper}>
                                <div className={classes.header}>All Indexed Sources</div>
                                <div className={classes.description}>
                                    Here is list of the indexed SharePoint / Teams sources:
                                </div>

                                {indexedSites.length === 0 && <div>No sites found</div>}
                                {indexedSites.length > 0 && (
                                    <>
                                        <DataGrid
                                            items={indexedSites}
                                            columns={columns}
                                            sortable
                                            selectionMode="multiselect"
                                            getRowId={(item: ISiteInfo) => {
                                                console.log(item.siteId);
                                                return item.siteId;
                                            }}
                                            selectedItems={selectedRows}
                                            focusMode="composite"
                                            resizableColumns
                                            onSelectionChange={onSelectionChange}
                                            columnSizingOptions={columnSizingOptions}
                                        >
                                            <DataGridHeader>
                                                <DataGridRow
                                                    selectionCell={{
                                                        checkboxIndicator: { 'aria-label': 'Select all rows' },
                                                    }}
                                                >
                                                    {({ renderHeaderCell }) => (
                                                        <DataGridHeaderCell>{renderHeaderCell()}</DataGridHeaderCell>
                                                    )}
                                                </DataGridRow>
                                            </DataGridHeader>
                                            <DataGridBody<ISiteInfo>>
                                                {({ item, rowId }) => (
                                                    <DataGridRow<ISiteInfo>
                                                        key={rowId}
                                                        selectionCell={{
                                                            checkboxIndicator: { 'aria-label': 'Select row' },
                                                        }}
                                                    >
                                                        {({ renderCell }) => (
                                                            <DataGridCell>{renderCell(item)}</DataGridCell>
                                                        )}
                                                    </DataGridRow>
                                                )}
                                            </DataGridBody>
                                        </DataGrid>
                                    </>
                                )}
                            </div>
                            <div>
                                <Button
                                    disabled={selectedRows.size == 0}
                                    appearance="primary"
                                    icon={<SaveRegular />}
                                    onClick={onSavePreference}
                                >
                                    Save preference
                                </Button>
                            </div>
                        </>
                    )}
                </div>            
        </TabView>
    );
};
