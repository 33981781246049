import { makeStyles, shorthands, tokens } from '@fluentui/react-components';
import React from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import remarkSuperSub from 'remark-supersub';
import { IChatMessage } from '../../../libs/models/ChatMessage';
import * as utils from './../../utils/TextUtils';

const useClasses = makeStyles({
    content: {
        wordBreak: 'break-word',
        '& p': {
            marginTop: '0',
            marginBottom: '0',
        },
        '& pre': {
            backgroundColor: tokens.colorNeutralBackground6,
            color: tokens.colorNeutralForeground1,
            ...shorthands.padding('15px'),
        },
        '& code': {
            textWrap: 'wrap',
            ...shorthands.borderRadius('5px'),
        },
    },
    paragraphGap: {
        marginTop: '15px !important',
    },
    markdown: {
        '& a': {
            'text-decoration': 'none',
        },
    },
});

interface ChatHistoryTextContentProps {
    message: IChatMessage;
}

export const ChatHistoryTextContent: React.FC<ChatHistoryTextContentProps> = ({ message }) => {
    const classes = useClasses();
    const content =
        message.content === '{}'
            ? "I am sorry, but I can't find anything related to your question."
            : utils.formatChatTextContent(message);

    const totalParagraphs = message.content.match(/\n/g)?.length ?? 0;
    let currentParagraph = 0;

    return (
        <div className={classes.content}>
            <ReactMarkdown
                className={classes.markdown}
                remarkPlugins={[remarkGfm, remarkSuperSub]}
                components={{
                    sub: 'sub',
                    sup: 'sup',
                    p(props) {
                        currentParagraph++;
                        // eslint-disable-next-line
                        const { node, ...rest } = props;
                        const paragraphClass = currentParagraph < totalParagraphs ? classes.paragraphGap : '';
                        return <p className={paragraphClass} {...rest} />;
                    },
                }}
            >
                {content}
            </ReactMarkdown>
        </div>
    );
};
