import { makeStyles, shorthands, Text, tokens } from '@fluentui/react-components';
import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { AuthorRoles, ChatMessageType } from '../../../libs/models/ChatMessage';
import { isPlan } from '../../../libs/utils/PlanUtils';
import { useAppSelector } from '../../../redux/app/hooks';
import { RootState } from '../../../redux/app/store';
import { IConversationDisplayMode } from '../../../redux/features/app/AppState';
import { Conversations } from '../../../redux/features/conversations/ConversationsState';
import { Breakpoints, customTokens } from '../../../styles';
import { ChatListItem } from './ChatListItem';

const useClasses = makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        ...shorthands.gap(tokens.spacingVerticalXXS),
        paddingBottom: tokens.spacingVerticalXS,
    },
    header: {
        ...shorthands.borderBottom('1px', 'solid', 'rgb(189, 189, 189)'),
        ...shorthands.padding('2px', '5px', '5px', '5px'),
        marginTop: 0,
        marginBottom: '5px',
        paddingBottom: tokens.spacingVerticalXS,
        marginLeft: tokens.spacingHorizontalXL,
        marginRight: tokens.spacingHorizontalXL,
        fontWeight: tokens.fontWeightBold,
        fontSize: tokens.fontSizeBase300,
        color: customTokens.colorMeBackground,
        ...Breakpoints.small({
            display: 'none',
        }),
    },
});

interface IChatListSectionProps {
    onSelectChat?: () => void;
    header?: string;
    conversations: Conversations;
    fetchData?: () => void;
    hasMore?: boolean;
}

export const ChatListSection: React.FC<IChatListSectionProps> = ({
    onSelectChat,
    header,
    conversations,
    fetchData,
    hasMore,
}) => {
    const classes = useClasses();

    const { selectedId } = useAppSelector((state: RootState) => state.conversations);
    const { conversationDisplayMode } = useAppSelector((state: RootState) => state.app);

    const keys = Object.keys(conversations);

    return keys.length > 0 && fetchData && hasMore !== undefined ? (
        <div className={classes.root}>
            <Text className={classes.header}>{header}</Text>
            <InfiniteScroll
                dataLength={keys.length}
                next={fetchData}
                hasMore={hasMore}
                scrollableTarget="chat-list"
                loader={null}
            >
                {keys.map((id) => {
                    const convo = conversations[id];
                    const scope = convo.chatScope;
                    const messages = convo.messages;
                    const lastMessage = messages[convo.messages.length - 1];
                    const isSelected = id === selectedId;

                    const autoGeneratedTitleRegex =
                        /Copilot @ [0-9]{1,2}\/[0-9]{1,2}\/[0-9]{1,4}, [0-9]{2}:[0-9]{2}:[0-9]{2} [A,P]M/;
                    const firstUserMessage = messages.find(
                        (message) => message.authorRole !== AuthorRoles.Bot && message.type === ChatMessageType.Message,
                    );
                    const title = autoGeneratedTitleRegex.test(convo.title)
                        ? firstUserMessage?.content ?? 'New Chat'
                        : convo.title;

                    const chatListItem = (
                        <ChatListItem
                            id={id}
                            key={id}
                            isSelected={isSelected}
                            header={title}
                            scope={scope}
                            timestamp={convo.lastUpdatedTimestamp ?? lastMessage.timestamp}
                            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                            recentAutoRename={convo.recentAutoRename ?? false}
                            preview={
                                messages.length > 0
                                    ? lastMessage.type === ChatMessageType.Document
                                        ? 'Sent a file'
                                        : isPlan(lastMessage.content)
                                          ? 'Click to view proposed plan'
                                          : lastMessage.content
                                    : 'Click to start the chat'
                            }
                            botProfilePicture={convo.botProfilePicture}
                            isHidden={convo.isHidden ?? false}
                            onSelectChat={onSelectChat}
                        />
                    );

                    if (
                        (conversationDisplayMode === IConversationDisplayMode.Active && !convo.isHidden) ||
                        (conversationDisplayMode === IConversationDisplayMode.Hidden && convo.isHidden)
                    ) {
                        return chatListItem;
                    } else if (conversationDisplayMode === IConversationDisplayMode.All) {
                        return chatListItem;
                    } else {
                        return null;
                    }
                })}
            </InfiniteScroll>
        </div>
    ) : null;
};
