import { IChatMessage } from '../../libs/models/ChatMessage';
/*
 * Function to detect and convert URLs within a string into clickable links.
 * It wraps each link matched with anchor tags and applies safe href attributes.
 */
export function convertToAnchorTags(htmlString: string) {
    try {
        // Regular expression to match links, excluding any HTML tags at the end
        // Since response from bot is plain text, sometimes line breaks and other html tags are included in the response for readability.
        const linkRegex =
            /(?:https?):\/\/(\w+:?\w*)?(\S+)(:\d+)?(\/|\/([\w#!:.?+=&%!\-/]))?(?=(<br|<p|<div|<span)\s*\/>|$)/g;

        const result = htmlString.replace(linkRegex, function (link) {
            // Parse URL first -- URL class handles cybersecurity concerns related to URL parsing and manipulation
            const safeHref = new URL(link).toString();

            // Replace each link with anchor tags
            return `<a href="${safeHref}">${link}</a>`;
        });

        return result;
    } catch (e) {
        console.log(e);
        return htmlString;
    }
}

/*
 * Function to check if date is today.
 */
export function isToday(date: Date) {
    return date.toDateString() === new Date().toDateString();
}

/*
 * Function to render the date and/or time of a message.
 */
export function timestampToDateString(timestamp: number, alwaysShowTime = false) {
    const date = new Date(timestamp);
    const dateString = date.toLocaleDateString([], {
        month: 'numeric',
        day: 'numeric',
    });
    const timeString = date.toLocaleTimeString([], {
        hour: 'numeric',
        minute: '2-digit',
    });

    return date.toDateString() !== new Date().toDateString()
        ? alwaysShowTime
            ? dateString + ' ' + timeString // if the date is not today and we are always showing the time, show the date and time
            : dateString // if the date is not today and we are not always showing the time, only show the date
        : timeString; // if the date is today, only show the time
}

/*
 * Function to create a command link
 */
export function createCommandLink(command: string) {
    const escapedCommand = encodeURIComponent(command);
    const createCommandLink = `<span style="text-decoration: underline; cursor: pointer" data-command="${escapedCommand}" onclick="(function(){ let chatInput = document.getElementById('chat-input'); chatInput.value = decodeURIComponent('${escapedCommand}'); chatInput.focus(); return false; })();return false;">${command}</span>`;
    return createCommandLink;
}

function getCitationIndex(citationRef: string, message: IChatMessage) {
    const indexMatch = citationRef.match(/\d+/);
     if (indexMatch) {
         const index = Number(indexMatch[0]);
         if (message.citations && index < message.citations.length) {
             return index;
         }
     }
     return -1;
}
/*
 * Function to format chat text content to remove any html tags from it.
 */
export function formatChatTextContent(message: IChatMessage): string {
    let citationsUsed: string[] = [];
    const contentAsString = message.content
        .trim()
        .replace(/^sk:\/\/.*$/gm, (match: string) => createCommandLink(match))
        .replace(/^!sk:.*$/gm, (match: string) => createCommandLink(match))
        .replace(/\[\d+\]/gm, (match: string) => {
             if (!message.citations) return match;
             const index = getCitationIndex(match, message);
             if (index === -1) return match;
            const citation = message.citations[index];
            citationsUsed.push(citation);
            citationsUsed = [...new Set(citationsUsed)]
            return `^[${citationsUsed.length}]^`;
        });
    return contentAsString;
}

export function getUsedCitations(message: IChatMessage) {
    const usedCitations: Array<{ ref: string; index: number; actualIndex?: number }> = [];

    if (!message.citations) return usedCitations;
    const matches = message.content.match(/\[\d+\]/gm);
    if (matches) {
        matches.forEach((match) => {
            const index = getCitationIndex(match, message);
            if (index !== -1) {
                const citation = message.citations?.[index];
                if (usedCitations.findIndex((u) => u.actualIndex === index) === -1) {
                    if (citation) {
                        usedCitations.push({ ref: citation, index: usedCitations.length, actualIndex: index });
                    }
                }
            }
        });
    }

    return usedCitations;
}


export function convertMarkdownToTable(markdownText: string) {
    let contentAsTable = '';
    let nonTableContent = '';

    if (!markdownText.includes('---')) {
        return markdownText;
    }

    const markdownTextSanitized = markdownText;
    const rows = markdownTextSanitized.split('###').filter((r) => r);

    rows.forEach((row, rowIndex) => {
        const sanitizedRow = row.replace(/^ /g, '').replace('---', '').trim();

        contentAsTable += '<table>';

        const lines = sanitizedRow.split('\n').filter((r) => r && r !== '---');

        lines.forEach((line, lineIndex) => {
            const currentLine = line;

            let sanitizedLine = currentLine.trim();

            if (!sanitizedLine.startsWith('- **')) {
                sanitizedLine = sanitizedLine.replace(/\s?-\s?\*\s?\**\s?/g, ' - **');
                sanitizedLine = sanitizedLine.replace(/(\d.)\s?-*\*\s?\*/g, ' - **');
                sanitizedLine = sanitizedLine.replace(/^\s?-?\s?\*\s?\**\s?/g, ' - **');

                if (!sanitizedLine.startsWith(' - **')) {
                    sanitizedLine = sanitizedLine.replace(/^\s?-\s*/g, ' - **');
                }

                sanitizedLine = sanitizedLine.replace(/^(\*\*)/g, ' - **');
                sanitizedLine = sanitizedLine.replace(/^(\w* (\*\*))/g, ' - **');
            }

            if (!sanitizedLine.includes('**: ') && new RegExp(/^(\s*-\s?\*\*)/g).test(sanitizedLine)) {
                sanitizedLine = sanitizedLine.replace(/\*+\s?\**\s?:\s?/g, '**: ');
                sanitizedLine = sanitizedLine.replace(/\s?\**\s?:\s?\*+\s?/g, '**: ');
                sanitizedLine = sanitizedLine.replace(/\* \*/g, '**: ');

                if (!sanitizedLine.includes('**: ')) {
                    sanitizedLine = sanitizedLine.replace(/"?\s?:\s?"?/g, '**: ');
                }
            }

            if (!new RegExp(/\s?-\s?\*\s?\**\s?/g).test(sanitizedLine)) {
                if (rowIndex === 0 || lineIndex === lines.length - 1) {
                    nonTableContent += sanitizedLine !== '---' ? `<p style="margin: 0">${sanitizedLine}</p>` : '';
                } else {
                    contentAsTable += `<td colspan="2" class="header" style="background: white; padding: 8px 0 8px 0; font-weight: 600;">${sanitizedLine}</td>`;
                }
            } else if (sanitizedLine.includes('**:')) {
                const [key, value] = sanitizedLine.split('**:').map((item) => item.trim());

                const finalKey = key ? key.replace(/-\s?\*+/g, '').replaceAll('*', '') : 'UNDEFINED';

                const finalValue = value
                    ? value.replace(/^"/g, '').replace(/"$/g, '').replace(/^\*/g, '').replace(/\*$/g, '').trim()
                    : 'UNDEFINED';

                contentAsTable += '<tr>';

                contentAsTable += `<th style="${
                    lineIndex === 0 || (contentAsTable.includes('class="header"') && lineIndex === 1)
                        ? 'border-radius: 5px 0 0 0;'
                        : ''
                }">${finalKey}</th>`;

                if (finalKey === 'System ID') {
                    contentAsTable += `<td title='View more details' class="archiveDataLink" style="${
                        lineIndex === 0 || (contentAsTable.includes('class="header"') && lineIndex === 1)
                            ? 'border-radius: 0 5px 0 0;'
                            : ''
                    }">${finalValue}</td>`;
                } else {
                    contentAsTable += `<td style="${
                        lineIndex === 0 || (contentAsTable.includes('class="header"') && lineIndex === 1)
                            ? 'border-radius: 0 5px 0 0;'
                            : ''
                    }">${finalValue}</td>`;
                }

                contentAsTable += '</tr>';
            } else {
                nonTableContent += `<p>${sanitizedLine.replace(/^(\s*-\s?\*\*\s?)/g, '')}</p>`;
            }
        });

        contentAsTable += `</table>${nonTableContent}`;

        nonTableContent = '';
    });

    if (contentAsTable.startsWith('<table></table>')) {
        contentAsTable = contentAsTable.replace('<table></table>', '<table style="margin:0"></table>');
    }

    return `${contentAsTable}`;
}

export function getJsonFromText(text: string, getOnlyText = false) {
    const jsonObjects = [];
    const textObjects = [];

    let failedJsonItems = '';

    const jsonStart = text.indexOf('{');
    const jsonEnd = text.lastIndexOf('}');

    textObjects.push(jsonStart !== -1 ? text.substring(0, jsonStart) : '');
    textObjects.push(jsonEnd !== -1 ? text.substring(jsonEnd + 1, text.length) : '');

    if (getOnlyText) {
        return [null, textObjects];
    }

    const firstSplit = text.split('{').filter((r) => r);

    // eslint-disable-next-line @typescript-eslint/prefer-for-of
    for (let i = 0; i < firstSplit.length; i++) {
        const secondSplit = firstSplit[i].split('}').filter((r) => r);
        jsonObjects.push(`{${secondSplit[0]}}`);
    }

    const jsonContent = jsonObjects
        .map((item) => {
            let jsonToReturn = undefined;

            try {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                jsonToReturn = JSON.parse(item);
            } catch (e) {
                const sanitizedItem = item
                    .replace(/""/g, '"')
                    .replace(/:\s*"/g, ':"')
                    .replace(/":/g, '":"')
                    .replace(/,\n/g, '",\n')
                    .replace(/""/g, '"')
                    .replace(/":",/g, '":"",')
                    .replace(/":"\n/g, '":""\n');

                try {
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                    jsonToReturn = JSON.parse(sanitizedItem);
                } catch (e) {
                    failedJsonItems += `${item}\n`;
                    jsonToReturn = null;
                }
            }

            // eslint-disable-next-line @typescript-eslint/no-unsafe-return
            return jsonToReturn;
        })
        .filter((r) => r);

    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return [jsonContent, textObjects, failedJsonItems];
}
