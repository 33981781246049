import { Button } from '@fluentui/react-button';
import { Tooltip, makeStyles } from '@fluentui/react-components';
import {
    Dialog,
    DialogActions,
    DialogBody,
    DialogContent,
    DialogSurface,
    DialogTitle,
    DialogTrigger,
} from '@fluentui/react-dialog';
import { useChat } from '../../../../libs/hooks';
import { AlertType } from '../../../../libs/models/AlertType';
import { useAppDispatch, useAppSelector } from '../../../../redux/app/hooks';
import { RootState } from '../../../../redux/app/store';
import { IConversationDisplayMode } from '../../../../redux/features/app/AppState';
import { addAlert } from '../../../../redux/features/app/appSlice';
import { ConversationHiddenStatusChange } from '../../../../redux/features/conversations/ConversationsState';
import {
    setConversationAsHidden,
    setSelectedConversation,
} from '../../../../redux/features/conversations/conversationsSlice';
import { EyeOff16 } from '../../../shared/BundledIcons';

const useClasses = makeStyles({
    root: {
        width: '450px',
    },
    actions: {
        paddingTop: '10%',
        justifyContent: 'flex-end',
    },
});

interface IHideChatProps {
    chatId: string;
    chatName: string;
}

export const HideChatDialog: React.FC<IHideChatProps> = ({ chatName, chatId }) => {
    const classes = useClasses();
    const dispatch = useAppDispatch();
    const { conversations, selectedId } = useAppSelector((state: RootState) => state.conversations);
    const { conversationDisplayMode } = useAppSelector((state: RootState) => state.app);
    const chat = useChat();

    const onHideChat = async () => {
        const chatState = conversations[selectedId];
        await chat
            .editChat(chatId, chatName, chatState.memoryBalance, chatState.chatScope, true)
            .then(() => {
                dispatch(
                    setConversationAsHidden({ id: chatId, newHiddenState: true } as ConversationHiddenStatusChange),
                );
                if (conversationDisplayMode !== IConversationDisplayMode.All) {
                    dispatch(setSelectedConversation(''));
                }
            })
            .catch((e: any) => {
                const errorMessage = e instanceof Error ? e.message : String(e);
                throw new Error(errorMessage);
            });
    };

    const handleHideChat = () => {
        onHideChat().catch((e: any) => {
            const errorMessage = `Unable to hide chat. Details: ${e instanceof Error ? e.message : String(e)}`;
            dispatch(addAlert({ message: errorMessage, type: AlertType.Error }));
        });
    };

    const countActiveChats = () => {
        let activeChats = 0;
        Object.keys(conversations).forEach((key) => {
            if (!conversations[key].isHidden) {
                activeChats++;
            }
        });
        return activeChats;
    };

    return countActiveChats() > 1 ? (
        <Dialog modalType="alert">
            <DialogTrigger>
                <Tooltip content={'Hide chat session'} relationship="label">
                    <Button icon={<EyeOff16 />} appearance="transparent" aria-label="Edit" />
                </Tooltip>
            </DialogTrigger>
            <DialogSurface className={classes.root}>
                <DialogBody>
                    <DialogTitle>Are you sure you want to hide conversation &quot;{chatName}&quot;</DialogTitle>
                    <DialogContent>
                        You can restore hidden chats by:-
                        <ul>
                            <li>
                                Switching the conversation view to one of:-
                                <ul>
                                    <li>
                                        <b>&apos;All conversations&apos;</b>
                                    </li>
                                    <li>
                                        <b>&apos;Hidden conversations&apos;</b>
                                    </li>
                                </ul>
                            </li>
                            <li>Selecting the hidden conversation</li>
                            <li>
                                Clicking the &apos;<b>Restore</b>&apos; icon.
                            </li>
                        </ul>
                    </DialogContent>
                    <DialogActions className={classes.actions} style={{ flexDirection: 'row' }}>
                        <DialogTrigger action="close" disableButtonEnhancement>
                            <Button appearance="secondary" style={{ width: '70px' }}>
                                Cancel
                            </Button>
                        </DialogTrigger>
                        <DialogTrigger action="close" disableButtonEnhancement>
                            <Button
                                appearance="primary"
                                style={{ width: '150px' }}
                                onClick={() => {
                                    handleHideChat();
                                }}
                            >
                                Hide Conversation
                            </Button>
                        </DialogTrigger>
                    </DialogActions>
                </DialogBody>
            </DialogSurface>
        </Dialog>
    ) : (
        <Dialog modalType="alert">
            <DialogTrigger>
                <Tooltip content={'Hide chat session'} relationship="label">
                    <Button icon={<EyeOff16 />} appearance="transparent" aria-label="Edit" />
                </Tooltip>
            </DialogTrigger>
            <DialogSurface className={classes.root}>
                <DialogBody>
                    <DialogTitle>Cannot hide chat</DialogTitle>
                    <DialogContent>
                        The last active conversation cannot be hidden. If you want to hide this conversation, please
                        create a new one first and then try again.
                    </DialogContent>
                    <DialogActions className={classes.actions}>
                        <DialogTrigger action="close" disableButtonEnhancement>
                            <Button appearance="secondary">Cancel</Button>
                        </DialogTrigger>
                    </DialogActions>
                </DialogBody>
            </DialogSurface>
        </Dialog>
    );
};
